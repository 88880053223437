import q, { createContext as I, useContext as j, useRef as $, useState as x, useEffect as _, useCallback as P } from "react";
function O(s) {
  let l = "pending", r, f;
  const h = s.then(
    (p) => {
      l = "success", r = p;
    },
    (p) => {
      l = "error", f = p;
    }
  );
  return {
    promise: s,
    read() {
      switch (l) {
        case "pending":
          throw h;
        case "error":
          throw f;
        case "success":
          return r;
      }
    }
  };
}
const F = I(null);
function k() {
  const s = j(F);
  if (!s) throw new Error("Repo was not found on RepoContext.");
  return s;
}
const E = /* @__PURE__ */ new Map();
function N(s, { suspense: l } = { suspense: !1 }) {
  const r = k(), f = $(), [h, p] = x();
  let u = s ? E.get(s) : void 0;
  if (!u && s) {
    f.current?.abort(), f.current = new AbortController();
    const n = r.find(s, { signal: f.current.signal });
    u = O(n), E.set(s, u);
  }
  return _(() => {
    l || !u || u.promise.then((n) => {
      p(n);
    }).catch(() => {
      p(void 0);
    });
  }, [l, u]), l && u ? u.read() : h;
}
function V(s, l = { suspense: !1 }) {
  const r = N(s, l), [f, h] = x(() => r?.doc()), [p, u] = x();
  _(() => {
    h(r?.doc());
  }, [r]), _(() => {
    if (!r)
      return;
    const t = () => h(r.doc()), e = () => {
      u(new Error(`Document ${s} was deleted`));
    };
    return r.on("change", t), r.on("delete", e), () => {
      r.removeListener("change", t), r.removeListener("delete", e);
    };
  }, [r, s]);
  const n = P(
    (t, e) => {
      r.change(t, e);
    },
    [r]
  );
  if (p)
    throw p;
  return f ? [f, n] : [void 0, () => {
  }];
}
function T(s, { suspense: l = !1 } = {}) {
  const r = k(), [f, h] = x(() => /* @__PURE__ */ new Map()), p = [], u = /* @__PURE__ */ new Map();
  for (const n of s) {
    let t = E.get(n);
    if (!t)
      try {
        const e = r.find(n);
        t = O(e), E.set(n, t);
      } catch {
        continue;
      }
    try {
      const e = t.read();
      u.set(n, e);
    } catch (e) {
      e instanceof Promise ? p.push(t) : u.set(n, void 0);
    }
  }
  if (_(() => {
    p.length > 0 ? Promise.allSettled(p.map((n) => n.promise)).then(
      (n) => {
        n.forEach((t) => {
          if (t.status === "fulfilled") {
            const e = t.value;
            u.set(e.url, e);
          }
        }), h(u);
      }
    ) : h(u);
  }, [l, s]), l && p.length > 0)
    throw Promise.all(p.map((n) => n.promise));
  return f;
}
function W(s, { suspense: l = !0 } = {}) {
  const r = T(s, { suspense: l }), [f, h] = x(() => /* @__PURE__ */ new Map());
  _(() => {
    const u = /* @__PURE__ */ new Map();
    return r.forEach((n, t) => {
      if (n) {
        const e = () => {
          h((o) => {
            const c = new Map(o);
            return c.set(t, n.doc()), c;
          });
        };
        h((o) => {
          const c = new Map(o);
          return c.set(t, n.doc()), c;
        }), n.on("change", e), u.set(t, e);
      }
    }), h((n) => {
      const t = new Map(n);
      for (const [e] of t)
        r.has(e) || t.delete(e);
      return t;
    }), () => {
      r.forEach((n, t) => {
        const e = u.get(t);
        n && e && n.removeListener("change", e);
      });
    };
  }, [r]);
  const p = P(
    (u, n, t) => {
      const e = r.get(u);
      e && e.change(n, t);
    },
    [r]
  );
  return [f, p];
}
function A(s) {
  return s && s.__esModule && Object.prototype.hasOwnProperty.call(s, "default") ? s.default : s;
}
var C, R;
function z() {
  if (R) return C;
  R = 1;
  var s = q, l = function(f) {
    return typeof f == "function";
  }, r = function(f) {
    var h = s.useState(f), p = h[0], u = h[1], n = s.useRef(p), t = s.useCallback(function(e) {
      n.current = l(e) ? e(n.current) : e, u(n.current);
    }, []);
    return [p, t, n];
  };
  return C = r, C;
}
var B = z();
const L = /* @__PURE__ */ A(B);
var D = { exports: {} }, S;
function G() {
  return S || (S = 1, function(s) {
    var l = Object.prototype.hasOwnProperty, r = "~";
    function f() {
    }
    Object.create && (f.prototype = /* @__PURE__ */ Object.create(null), new f().__proto__ || (r = !1));
    function h(t, e, o) {
      this.fn = t, this.context = e, this.once = o || !1;
    }
    function p(t, e, o, c, w) {
      if (typeof o != "function")
        throw new TypeError("The listener must be a function");
      var v = new h(o, c || t, w), i = r ? r + e : e;
      return t._events[i] ? t._events[i].fn ? t._events[i] = [t._events[i], v] : t._events[i].push(v) : (t._events[i] = v, t._eventsCount++), t;
    }
    function u(t, e) {
      --t._eventsCount === 0 ? t._events = new f() : delete t._events[e];
    }
    function n() {
      this._events = new f(), this._eventsCount = 0;
    }
    n.prototype.eventNames = function() {
      var e = [], o, c;
      if (this._eventsCount === 0) return e;
      for (c in o = this._events)
        l.call(o, c) && e.push(r ? c.slice(1) : c);
      return Object.getOwnPropertySymbols ? e.concat(Object.getOwnPropertySymbols(o)) : e;
    }, n.prototype.listeners = function(e) {
      var o = r ? r + e : e, c = this._events[o];
      if (!c) return [];
      if (c.fn) return [c.fn];
      for (var w = 0, v = c.length, i = new Array(v); w < v; w++)
        i[w] = c[w].fn;
      return i;
    }, n.prototype.listenerCount = function(e) {
      var o = r ? r + e : e, c = this._events[o];
      return c ? c.fn ? 1 : c.length : 0;
    }, n.prototype.emit = function(e, o, c, w, v, i) {
      var m = r ? r + e : e;
      if (!this._events[m]) return !1;
      var a = this._events[m], g = arguments.length, y, d;
      if (a.fn) {
        switch (a.once && this.removeListener(e, a.fn, void 0, !0), g) {
          case 1:
            return a.fn.call(a.context), !0;
          case 2:
            return a.fn.call(a.context, o), !0;
          case 3:
            return a.fn.call(a.context, o, c), !0;
          case 4:
            return a.fn.call(a.context, o, c, w), !0;
          case 5:
            return a.fn.call(a.context, o, c, w, v), !0;
          case 6:
            return a.fn.call(a.context, o, c, w, v, i), !0;
        }
        for (d = 1, y = new Array(g - 1); d < g; d++)
          y[d - 1] = arguments[d];
        a.fn.apply(a.context, y);
      } else {
        var H = a.length, b;
        for (d = 0; d < H; d++)
          switch (a[d].once && this.removeListener(e, a[d].fn, void 0, !0), g) {
            case 1:
              a[d].fn.call(a[d].context);
              break;
            case 2:
              a[d].fn.call(a[d].context, o);
              break;
            case 3:
              a[d].fn.call(a[d].context, o, c);
              break;
            case 4:
              a[d].fn.call(a[d].context, o, c, w);
              break;
            default:
              if (!y) for (b = 1, y = new Array(g - 1); b < g; b++)
                y[b - 1] = arguments[b];
              a[d].fn.apply(a[d].context, y);
          }
      }
      return !0;
    }, n.prototype.on = function(e, o, c) {
      return p(this, e, o, c, !1);
    }, n.prototype.once = function(e, o, c) {
      return p(this, e, o, c, !0);
    }, n.prototype.removeListener = function(e, o, c, w) {
      var v = r ? r + e : e;
      if (!this._events[v]) return this;
      if (!o)
        return u(this, v), this;
      var i = this._events[v];
      if (i.fn)
        i.fn === o && (!w || i.once) && (!c || i.context === c) && u(this, v);
      else {
        for (var m = 0, a = [], g = i.length; m < g; m++)
          (i[m].fn !== o || w && !i[m].once || c && i[m].context !== c) && a.push(i[m]);
        a.length ? this._events[v] = a.length === 1 ? a[0] : a : u(this, v);
      }
      return this;
    }, n.prototype.removeAllListeners = function(e) {
      var o;
      return e ? (o = r ? r + e : e, this._events[o] && u(this, o)) : (this._events = new f(), this._eventsCount = 0), this;
    }, n.prototype.off = n.prototype.removeListener, n.prototype.addListener = n.prototype.on, n.prefixed = r, n.EventEmitter = n, s.exports = n;
  }(D)), D.exports;
}
var J = G();
const K = /* @__PURE__ */ A(J), M = new K(), X = ({
  handle: s,
  localUserId: l,
  offlineTimeout: r = 3e4,
  getTime: f = () => (/* @__PURE__ */ new Date()).getTime()
}) => {
  const [h, p, u] = L({}), [n, t, e] = L({});
  return _(() => {
    const o = (v) => {
      const [i, m] = v.message;
      i !== l && (e.current[i] || M.emit("new_peer", v), p({
        ...u.current,
        [i]: m
      }), t({
        ...e.current,
        [i]: f()
      }));
    }, c = () => {
      const v = u.current, i = e.current, m = f();
      for (const a in i)
        m - i[a] > r && (delete v[a], delete i[a]);
      p(v), t(i);
    };
    s.on("ephemeral-message", o);
    const w = setInterval(
      c,
      r
    );
    return () => {
      s.removeListener("ephemeral-message", o), clearInterval(w);
    };
  }, [s, l, r, f]), [h, n];
}, Y = ({
  handle: s,
  userId: l,
  initialState: r,
  heartbeatTime: f = 15e3
}) => {
  const [h, p, u] = L(r), n = (t) => {
    const e = typeof t == "function" ? t(u.current) : t;
    p(e), s.broadcast([l, e]);
  };
  return _(() => {
    if (!l)
      return;
    const t = () => void s.broadcast([l, u.current]);
    t();
    const e = setInterval(t, f);
    return () => void clearInterval(e);
  }, [s, l, f]), _(() => {
    let t;
    const e = M.on("new_peer", () => {
      t = setTimeout(
        () => s.broadcast([l, u.current]),
        500
        // Wait for the peer to be ready
      );
    });
    return () => {
      e.off("new_peer"), t && clearTimeout(t);
    };
  }, [s, l, M]), [h, n];
};
export {
  F as RepoContext,
  N as useDocHandle,
  T as useDocHandles,
  V as useDocument,
  W as useDocuments,
  Y as useLocalAwareness,
  X as useRemoteAwareness,
  k as useRepo
};
